// CheckoutPage.js
import React, { useState } from "react";
import { useCart } from "../../../context/CartContext"; // Import Cart context
import { collection, addDoc } from "firebase/firestore"; // Firestore import
import { db } from "../../../firebaseconfig"; // Firebase configuration import
import styles from "./CheckoutOne.module.css";
import { useNavigate } from "react-router-dom";

const CheckoutOne = () => {
  const navigate = useNavigate(); // Initialize navigate


  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    cardNumber: "",
    expirationDate: "",
    cvv: "",
  });

  const { cart } = useCart(); // Access cart from context

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const calculateTotal = () => {
    return cart
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Process payment logic (e.g., Stripe integration) before uploading to Firestore

    // Prepare data to upload
    const orderData = {
      name: formValues.name,
      email: formValues.email,
      address: {
        addressLine1: formValues.addressLine1,
        addressLine2: formValues.addressLine2,
        city: formValues.city,
        state: formValues.state,
        zip: formValues.zip,
        country: formValues.country,
      },
      total: calculateTotal(),
      cartItems: cart.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      })),
      paymentDetails: {
        cardNumber: formValues.cardNumber, // You may want to avoid storing sensitive info like this.
        expirationDate: formValues.expirationDate,
        cvv: formValues.cvv,
      },
      timestamp: new Date(), // Timestamp of the order
    };

    try {
      // Add order to Firestore
      const docRef = await addDoc(collection(db, "orders"), orderData);
      console.log("Order added with ID: ", docRef.id);
      alert("Payment processed successfully!");
      navigate('/checkout-complete');
    } catch (error) {
      console.error("Error adding order: ", error);
    }
  };

  return (
    <div className={styles.checkoutPageContainer}>
      <div className={styles.checkoutContent}>
        {/* Checkout Form */}
        <div className={styles.checkoutFormContainer}>
          <h1 className={styles.checkoutHeading}>Checkout</h1>

          <form className={styles.checkoutForm} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="name">Name on Card</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Shipping Address Section */}
            <div className={styles.formGroup}>
              <label htmlFor="addressLine1">Street Address</label>
              <input
                type="text"
                id="addressLine1"
                name="addressLine1"
                value={formValues.addressLine1}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="addressLine2">
                Apartment, suite, etc. (optional)
              </label>
              <input
                type="text"
                id="addressLine2"
                name="addressLine2"
                value={formValues.addressLine2}
                onChange={handleInputChange}
              />
            </div>

            <div className={styles.addressRow}>
              <div className={styles.formGroup}>
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formValues.city}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={formValues.state}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="zip">Zip Code</label>
                <input
                  type="text"
                  id="zip"
                  name="zip"
                  value={formValues.zip}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={formValues.country}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Payment Details Section */}
            <div className={styles.formGroup}>
              <label htmlFor="cardNumber">Card Number</label>
              <input
                type="text"
                id="cardNumber"
                name="cardNumber"
                value={formValues.cardNumber}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.cardDetails}>
              <div className={styles.formGroup}>
                <label htmlFor="expirationDate">Expiration</label>
                <input
                  type="text"
                  id="expirationDate"
                  name="expirationDate"
                  placeholder="MM/YY"
                  value={formValues.expirationDate}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="cvv">CVV</label>
                <input
                  type="text"
                  id="cvv"
                  name="cvv"
                  value={formValues.cvv}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <button type="submit" className={styles.checkoutButton}>
              Complete Payment
            </button>
          </form>
        </div>

        {/* Cart Summary Section */}
        <div className={styles.cartSummaryContainer}>
          <h2 className={styles.summaryHeading}>Cart Summary</h2>
          <div className={styles.cartItems}>
            {cart.map((item, index) => (
              <div key={index} className={styles.cartItem}>
                <p className={styles.itemName}>{item.name}</p>
                <p className={styles.itemQuantity}>
                  {item.quantity} x ${item.price.toFixed(2)}
                </p>
              </div>
            ))}
          </div>

          <div className={styles.totalAmount}>
            <p>Total: ${calculateTotal()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutOne;
