import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerLeft}>
          <h3>Gonblu</h3>
          <p>Gonblu mission statement or slogan goes here.</p>
        </div>

        <div className={styles.footerCenter}>
          <ul>
            <li>
              <Link to="/about">
                <a>About Us</a>
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <a>Contact</a>
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy">
                <a>Privacy Policy</a>
              </Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">
                <a>Terms & Conditions</a>
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.footerRight}>
          <p>Connect with us:</p>
          <div className={styles.socialIcons}>
            <a href="#">
              <i className="fab fa-facebook-f">Facebook</i>
            </a>
            <a href="#">
              <i className="fab fa-twitter">Twitter</i>
            </a>
            <a href="#">
              <i className="fab fa-instagram">Insta</i>
            </a>
            <a href="#">
              <i className="fab fa-linkedin-in">Linkedin</i>
            </a>
          </div>
        </div>
      </div>

      <div className={styles.footerBottom}>
        <p>&copy; 2024 Gonblu. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
