import React, { useState } from "react";
import logo from "../../assets/imgs/logo512.png";
import tshirtExample from "../../assets/imgs/tshirtexample.png";

import { useParams } from "react-router-dom";
import styles from "./ProductDetail.module.css";
import { useCart } from "../../context/CartContext"; // Import the useCart hook from CartContext

const clothes = [
  {
    id: 1,
    name: "T-shirt",
    price: 25,
    image: tshirtExample,
    description: "A cool and comfortable T-shirt.",
  },
  {
    id: 2,
    name: "Jeans",
    price: 25,
    image: tshirtExample,
    description: "Stylish and durable jeans.",
  },
  {
    id: 3,
    name: "Jacket",
    price: 100,
    image: tshirtExample,
    description: "A warm and fashionable jacket.",
  },
  {
    id: 4,
    name: "Sneakers",
    price: 100,
    image: tshirtExample,
    description: "Comfortable sneakers for everyday use.",
  },
  {
    id: 5,
    name: "Hat",
    price: 100,
    image: tshirtExample,
    description: "A stylish hat for sunny days.",
  },
  {
    id: 6,
    name: "Sunglasses",
    price: 100,
    image: tshirtExample,
    description: "Chic sunglasses for eye protection.",
  },
  {
    id: 7,
    name: "Dress",
    price: 100,
    image: tshirtExample,
    description: "A beautiful summer dress.",
  },
  {
    id: 8,
    name: "Shorts",
    price: 100,
    image: tshirtExample,
    description: "Perfect shorts for casual outings.",
  },
];

const ProductDetail = () => {
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCart(); // Use the useCart hook to access addToCart from context

  const handleAddToCart = () => {
    if (size) {
      addToCart({ ...productForPage, size, quantity });
    } else {
      alert("Please select a size");
    }
    alert("Added to cart");
  };

  const { productId } = useParams();

  // Find the product by its ID
  const productForPage = clothes.find(
    (item) => item.id === parseInt(productId)
  );

  if (!productForPage) {
    return <h2>Product not found!</h2>;
  }

  return (
    <div className="clothing-detail">
      <div className={styles.productDetailContainer}>
        <img
          src={productForPage.image}
          alt={productForPage.name}
          className={styles.productImage}
        />
        <div className={styles.productInfo}>
          <h1>{productForPage.name}</h1>
          <p className={styles.productPrice}>{productForPage.price}</p>
          <select value={size} onChange={(e) => setSize(e.target.value)}>
            <option value="">Select Size</option>
            <option>Small</option>
            <option>Med</option>
            <option>Large</option>
          </select>
          <p className={styles.productDescription}>
            {productForPage.description}
          </p>
          <button className={styles.addToCartButton} onClick={handleAddToCart}>
            Add to Cart
          </button>
        </div>
      </div>
      {/* 
      <img src={logo} alt={product.name} />
      <h2>{product.name}</h2>
      <p>{product.description}</p>
      <p>Price: ${product.price}</p>

      <label htmlFor="size">Select Size:</label>
      <select value={size} onChange={(e) => setSize(e.target.value)}>
        <option value="">Select Size</option>
        {product.sizes.map((s) => (
          <option key={s} value={s}>
            {s}
          </option>
        ))}
      </select>

      <label htmlFor="quantity">Quantity:</label>
      <input
        type="number"
        value={quantity}
        onChange={(e) => setQuantity(e.target.value)}
        min="1"
      />

      <button onClick={handleAddToCart}>Add to Cart</button> */}
    </div>
  );
};

export default ProductDetail;
