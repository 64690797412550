// CartPage.js
import React from "react";
import { useCart } from "../../context/CartContext"; // Import the useCart hook from CartContext
import styles from "./Cart.module.css";
import { useNavigate } from "react-router-dom";

const CartPage = () => {
  const { cart, removeFromCart } = useCart(); // Access cart and removeFromCart

  const calculateTotal = () => {
    return cart
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2);
  };

  const navigate = useNavigate();

  const goToCheckout = () => {
    navigate("/checkout"); // This will navigate to the shop page
  };

  return (
    <div className={styles.cartPageContainer}>
      <h1 className={styles.cartHeading}>Shopping Cart</h1>
      <div className={styles.cartContent}>
        {/* Left side: Cart items */}
        <div className={styles.cartItemsSection}>
          {cart.length > 0 ? (
            cart.map((item, index) => (
              <div key={index} className={styles.cartItem}>
                <img
                  src={item.image}
                  alt={item.name}
                  className={styles.itemImage}
                />
                <div className={styles.itemDetails}>
                  <h2 className={styles.itemName}>{item.name}</h2>
                  <p className={styles.itemPrice}>${item.price}</p>
                  <p className={styles.itemQuantity}>
                    Quantity: {item.quantity}
                  </p>
                  <p className={styles.itemSize}>Size: {item.size}</p>
                  <button
                    onClick={() => removeFromCart(index)}
                    className={styles.removeButton}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className={styles.emptyCartMessage}>Your cart is empty</p>
          )}
        </div>

        {/* Right side: Total price information */}
        <div className={styles.cartSummarySection}>
          <h2 className={styles.summaryHeading}>Order Summary</h2>
          <div className={styles.summaryContent}>
            <p className={styles.subtotal}>
              <span>Subtotal</span>
              <span>${calculateTotal()}</span>
            </p>
            <p className={styles.shipping}>
              <span>Shipping</span>
              <span>$5.00</span>
            </p>
            <p className={styles.total}>
              <span>Total</span>
              <span>${(parseFloat(calculateTotal()) + 5.0).toFixed(2)}</span>
            </p>
            <button className={styles.checkoutButton} onClick={goToCheckout}>
              Proceed to Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
