// import classes from "./MainNavigation.module.css";
import { Link, useLocation } from "react-router-dom";

import React, { useState, useEffect } from "react";
import cartIcon from "../../assets/imgs/shoppingCart.png";

import "./MainNavigation.css";

const MainNavigation = () => {
  const [scrolling, setScrolling] = useState(false);
  // const tab = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 25) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <div className={`scrolling-text ${scrolling ? "hidden" : ""}`}>
        <p className="scrolling-text-info">
          This is a scrolling white text message, This is a scrolling white text
          message, This is a scrolling white text message
        </p>
      </div>
      <nav className={`navbar ${scrolling ? "fixed" : ""}`}>
        <div className="navbar-content">
          <Link to="/">
            <div className="nav-title">Gonblu</div>
          </Link>
          <div className="nav-sub-container">
            <Link to="/shop" className="nav-sub-link" >
              <div>Shop</div>
            </Link>
          <Link to="/about"  className="nav-sub-link">
            <div>About Us</div>  
          </Link>
          <Link to="/contact"  className="nav-sub-link">
            <div>Contact</div>
          </Link>
          </div>
          <Link to="/cart">
            <img src={cartIcon} className="nav-cart-icon"/>
          </Link>
        </div>
      </nav>
    </div>
  );
};
export default MainNavigation;
