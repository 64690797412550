import React from "react";
import styles from "./Shop.module.css";
import tshirtExample from "../../assets/imgs/tshirtexample.png";
import { useNavigate } from "react-router-dom";

const Shop = () => {
  const navigate = useNavigate();

  const clothes = [
    { id: 1, name: "T-shirt", price: 25, image: tshirtExample },
    // { id: 2, name: "t", price: 50, image: tshirtExample },
    // { id: 3, name: "t", price: 100, image: tshirtExample },
    // { id: 4, name: "t", price: 80, image: tshirtExample },
    // { id: 5, name: "t", price: 15, image: tshirtExample },
    // { id: 6, name: "t", price: 23, image: tshirtExample },
    // { id: 7, name: "t", price: 40, image: tshirtExample },
    // { id: 8, name: "t", price: 40, image: tshirtExample },
  ];

  const handleCardClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div className={styles.shopContainer}>
      <h1 className={styles.title}>Clothing Shop</h1>
      <h3 className={styles.subtitle}>This is where subtitle goes</h3>
      <div className={styles.grid}>
        {clothes.map((item) => (
          <div
            className={styles.card}
            key={item.id}
            onClick={() => handleCardClick(item.id)}
          >
            <img src={item.image} alt={item.name} className={styles.image} />
            <div className={styles.info}>
              <h3>{item.name}</h3>
              <p>{item.price}</p>
              <button className={styles.addToCartButton}>Add to Cart</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shop;
