// CheckoutPage.js
import React, { useState } from "react";
import { useCart } from "../../../context/CartContext"; // Import Cart context
import { collection, addDoc } from "firebase/firestore"; // Firestore import
import { db } from "../../../firebaseconfig"; // Firebase configuration import
import styles from "./CheckoutComplete.module.css";

const CheckoutComplete = ( ) => {
  const [formValues, setFormValues] = useState({
    review: "",
    timestamp: null
  });



  const { cart } = useCart(); // Access cart from context

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const calculateTotal = () => {
    return cart 
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2);
  };

  const handleSubmit = async (e) => {
  
    e.preventDefault();
    // Process payment logic (e.g., Stripe integration) before uploading to Firestore

    // Prepare data to upload
    const reviewData = {
      review: formValues.review,
      timestamp: new Date(), // Timestamp of the order
    };

    try {
      // Add order to Firestore
      const docRef = await addDoc(collection(db, "reviews"), reviewData);
      console.log("Review added with ID: ", docRef.id);
      alert("Review added");
    } catch (error) {
      console.error("Review adding error: ", error);
    }
  };

  return (
    <div className={styles.checkoutPageContainer}>
      <div className={styles.checkoutContent}>
        {/* Checkout Form */}
        <div className={styles.checkoutFormContainer}>
          <h1 className={styles.checkoutHeading}>Thank you for your order!</h1>
    <p>Gonblu is dedicated to the highest of experience.</p>
          <div style={{height: "200px"}}/>
          <form className={styles.checkoutForm} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="review">How was your experience?</label>
              <input
                type="text"
                id="review"
                name="review"
                value={formValues.review}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit" className={styles.checkoutButton}>
              Leave Review
            </button>
          </form>
        </div>

        {/* Cart Summary Section */}
        <div className={styles.cartSummaryContainer}>
          <h2 className={styles.summaryHeading}>Cart Summary</h2>
          <div className={styles.cartItems}>
            {cart.map((item, index) => (
              <div key={index} className={styles.cartItem}>
                <p className={styles.itemName}>{item.name}</p>
                <p className={styles.itemQuantity}>
                  {item.quantity} x ${item.price.toFixed(2)}
                </p>
              </div>
            ))}
          </div>

          <div className={styles.totalAmount}>
            <p>Total: ${calculateTotal()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutComplete;
