import "./App.css";
import Layout from "./components/Layout/Layout";
import Error404 from "./pages/Static/404/404";
import Overview from "./pages/Overview/Overview";

import { Navigate, Route, Routes } from "react-router-dom";
import Shop from "./pages/Shop/Shop";
import ProductDetail from "./pages/ProductDetail/ProductDetail";
import CartPage from "./pages/Cart/Cart";
import AboutPage from "./pages/Static/About/About";
import ContactPage from "./pages/Static/Contact/Contact";
import TermsAndConditionsPage from "./pages/Static/TermsAndConditions/TermsAndConditions";
import PrivacyPolicyPage from "./pages/Static/PrivacyPolicy/PrivacyPolicy";
import CheckoutOne from "./pages/Checkout/CheckoneOne/CheckoutOne";
import CheckoutComplete from "./pages/Checkout/CheckoneComplete.js/CheckoutComplete";

function App() {
  return (
    <Layout>
      <Routes>
        <Route exact path="/" element={<Overview />} />
        <Route
          exact
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route path="/product/:productId" element={<ProductDetail />} />{" "}
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<CheckoutOne />} />\
        <Route path="/checkout-complete" element={<CheckoutComplete />} />

        {/* Dynamic product route */}
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/404" element={<Error404 />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </Layout>
  );
}

export default App;
