import styles from "./Overview.module.css";
import ProductDetailPage from "../ProductDetail/ProductDetail";
import CartPage from "../Cart/Cart";
import { CartProvider, useCart } from "../../context/CartContext";
import bckgrndVid from "../../assets/imgs/gonBluVert.MOV";
import { useNavigate } from "react-router-dom";

const Overview = () => {
  const { cart, addToCart, removeFromCart } = useCart();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/shop"); // This will navigate to the shop page
  };

  const product = {
    name: "T-shirt",
    description: "A stylish T-shirt.",
    price: 25.99,
    sizes: ["S", "M", "L", "XL"],
    image: "/path/to/tshirt.jpg",
  };

  return (
    <div>
      <div>
        {/* <ProductDetailPage product={product} addToCart={addToCart} />
        <CartPage cartItems={cart} removeFromCart={removeFromCart} /> */}
        <div className={styles.videoContainer}>
          <video autoPlay loop muted className={styles.backgroundVideo}>
            <source src={bckgrndVid} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div className={styles.overlayContent}>
            <h1>Welcome to Our Website</h1>
            <p>This is a tagline or some important information.</p>
            <button className={styles.ctaButton} onClick={handleGetStarted}>
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
